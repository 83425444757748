import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import {render} from 'storyblok-rich-text-react-renderer';

import leftCircle from '@/assets/components/Content404/leftCircle.svg';
import rightCircle from '@/assets/components/Content404/rightCircle.svg';
import Button from '@/components/Button/Button';

import * as styles from './Content404.module.scss';

const Content404 = ({message, theme, CTA}) => {
  return (
    <div className={classNames(styles.content404)}>
      <div className={'container'}>
        <div className={classNames('row', styles.contentRow)}>
          <div
            className={classNames(
              'col-12 col-md-6 col-lg-4',
              styles.textWrapper,
              styles['theme' + theme]
            )}
          >
            {render(message)}
          </div>
        </div>
        <div className={classNames('row', styles.ctaRow)}>
          <div className={classNames('col-12 col-md-6 col-lg-4', styles.ctaWrapper)}>
            {CTA &&
              CTA[0] &&
              CTA[0].button.map((cta) => (
                <div key={cta._uid}>
                  <Button {...cta} />
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className={classNames(styles.circlesWrapper)}>
        <div className={classNames(styles.circleLeftWrapper, styles.oneCircleWrapper)}>
          <img src={leftCircle} alt='' />
        </div>
        <div className={classNames(styles.circleRightWrapper, styles.oneCircleWrapper)}>
          <img src={rightCircle} alt='' />
        </div>
      </div>
    </div>
  );
};

export default Content404;

Content404.propTypes = {
  message: PropTypes.object,
  theme: PropTypes.string,
  CTA: PropTypes.array,
};
