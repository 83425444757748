import classNames from 'classnames';
import {gsap} from 'gsap';
import {ScrollTrigger} from 'gsap/ScrollTrigger';
import PropTypes from 'prop-types';
import React, {useRef} from 'react';

import useObserver from '@/hooks/useObserver';

import * as styles from './Layout.module.scss';

if (typeof window !== `undefined`) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals('ScrollTrigger', ScrollTrigger);
}

export const Layout = ({children, layoutBackgroundColor, fixedNav}) => {
  const mainRef = useRef(null);

  const resizeMain = () => {
    ScrollTrigger.refresh();
  };
  useObserver({callback: resizeMain, element: mainRef});

  return (
    <>
      <main
        className={classNames(styles.mainLayout, styles['background' + layoutBackgroundColor], {
          [`fixedNav`]: fixedNav,
        })}
        ref={mainRef}
      >
        {children}
      </main>
    </>
  );
};

export default Layout;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  layoutBackgroundColor: PropTypes.string,
  fixedNav: PropTypes.bool,
};
