import classNames from 'classnames';
import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import * as styles from './Button.module.scss';

export const Button = ({
  mode,
  label,
  href: {cached_url, linktype},
  targetBlank,
  nofollowNoopener,
}) => {
  if (cached_url && linktype === 'story' && cached_url.startsWith('/')) {
    cached_url = cached_url.substring(1);
  }

  if (cached_url && cached_url.includes('home')) {
    cached_url.replace('home', '');
  }
  return (
    <span className={classNames(styles.button, styles[mode])}>
      {linktype === 'story' && <Link to={`/${cached_url}`}>{label}</Link>}
      {linktype === 'url' &&
        (targetBlank ? (
          <a href={cached_url} rel={`noreferrer ${nofollowNoopener}`} target='_blank'>
            {label}
          </a>
        ) : (
          <a href={cached_url} rel={`${nofollowNoopener}`}>
            {label}
          </a>
        ))}
    </span>
  );
};

export default Button;

Button.propTypes = {
  mode: PropTypes.string,
  label: PropTypes.string.isRequired,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  nofollowNoopener: PropTypes.string,
  targetBlank: PropTypes.bool,
};

Button.defaultProps = {
  mode: 'primary',
  href: {},
  nofollowNoopener: '',
  targetBlank: false,
};
